export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは11月より%d点上がりました。変化が見られたのはどの項目ですか？<br>\n新しい年を迎えました。\r\n「<b>一年の計は元旦にあり</b>」今年1年を元気に過ごすことができるよう、今日から始められる健康習慣の目標を1つでも見つけてみませんか？<a href=\"https://go.andwell.jp/library/tag/a21260e3-5e2f-11ec-9ce8-0af363eb5062\">&wellのコラム</a>を参考にするのもおすすめです。\n\n",
      "down": "生活習慣スコアは11月より%d点下がりました。変化が見られたのはどの項目ですか？<br>\n新しい年を迎えました。\r\n「<b>一年の計は元旦にあり</b>」今年1年を元気に過ごすことができるよう、今日から始められる健康習慣の目標を1つでも見つけてみませんか？<a href=\"https://go.andwell.jp/library/tag/a21260e3-5e2f-11ec-9ce8-0af363eb5062\">&wellのコラム</a>を参考にするのもおすすめです。\n\n",
      "equal100": "生活習慣スコアは11月に引き続き%満点%です。素晴らしい！<br>\n新しい年を迎えました。\r\n「<b>一年の計は元旦にあり</b>」今年1年を元気に過ごすことができるよう、今日から始められる健康習慣の目標を1つでも見つけてみませんか？&wellのコラム</a>を参考にするのもおすすめです。\n\n",
      "equal": "生活習慣スコアは11月と同じ点数です。<br>\n新しい年を迎えました。\r\n「<b>一年の計は元旦にあり</b>」今年1年を元気に過ごすことができるよう、今日から始められる健康習慣の目標を1つでも見つけてみませんか？<a href=\"https://go.andwell.jp/library/tag/a21260e3-5e2f-11ec-9ce8-0af363eb5062\">&wellのコラム</a>を参考にするのもおすすめです。\n\n"
    },
    "steps": {
      "up": "歩数スコアは11月より%d点上がりました。この調子で頑張りましょう！<br>\n1/17より、<b>Walk 個人チャレンジ 3rd</b>のエントリーが開始しております。今年度最後の個人チャレンジ、過去最高の記録を目指しましょう！\n\n",
      "down": "歩数スコアは11月より%d点下がりました。<br>\n1/17より、<b>Walk 個人チャレンジ 3rd</b>のエントリーが開始しております。今年度最後の個人チャレンジ、過去最高の記録を目指しましょう！\n\n",
      "equal": "歩数スコアは11月と同じ点数です。<br>\n1/17より、<b>Walk 個人チャレンジ 3rd</b>のエントリーが開始しております。今年度最後の個人チャレンジ、過去最高の記録を目指しましょう！\n\n"
    },
    "exercise": {
      "up": "運動のスコアは11月より%d点上がりました。素晴らしい！<br>\n適度な運動は、<b>免疫力アップ</b>にもつながります。<a href=\"https://go.andwell.jp/library/tag/9caf9806-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>も参考にしてみては？\n\n",
      "down": "運動のスコアは11月より%d点下がりました。先月の自分を振り返ってみましょう。<br>\n適度な運動は、<b>免疫力アップ</b>にもつながります。<a href=\"https://go.andwell.jp/library/tag/9caf9806-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>も参考にしてみては？\n\n",
      "equal": "運動のスコアは11月と同じ点数です。<br>\n適度な運動は、<b>免疫力アップ</b>にもつながります。<a href=\"https://go.andwell.jp/library/tag/9caf9806-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>も参考にしてみては？\n\n"
    },
    "meal": {
      "up": "食事のスコアは11月より%d点上がりました。<br>\nたった1日食べ過ぎただけでは脂肪はつきません。食べ過ぎた後の食事が大切です。野菜中心のメニューを意識して、<b>食物繊維</b>と<b>ビタミン</b>をたっぷり摂りましょう。❝<b>48時間以内の調整</b>❞がポイントです。\n\n",
      "down": "食事のスコアは11月より%d点下がりました。<br>\nたった1日食べ過ぎただけでは脂肪はつきません。食べ過ぎた後の食事が大切です。野菜中心のメニューを意識して、<b>食物繊維</b>と<b>ビタミン</b>をたっぷり摂りましょう。❝<b>48時間以内の調整</b>❞がポイントです。\n\n",
      "equal": "食事のスコアは11月と同じ点数です。<br>\nたった1日食べ過ぎただけでは脂肪はつきません。食べ過ぎた後の食事が大切です。野菜中心のメニューを意識して、<b>食物繊維</b>と<b>ビタミン</b>をたっぷり摂りましょう。❝<b>48時間以内の調整</b>❞がポイントです。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは11月より%d点上がりました。<br>\n飲酒後の入浴には要注意！急激な気温変化とアルコールの作用で心臓や血管に大きな負担がかかります。飲酒直後の入浴は避け、どうしても入りたい時はぬるめのシャワーで済ませるようにしましょう。\n\n",
      "down": "飲酒のスコアは11月より%d点下がりました。飲み過ぎには気をつけましょう。<br>\n飲酒後の入浴には要注意！急激な気温変化とアルコールの作用で心臓や血管に大きな負担がかかります。飲酒直後の入浴は避け、どうしても入りたい時はぬるめのシャワーで済ませるようにしましょう。\n\n",
      "equal": "飲酒のスコアは11月と同じ点数です。<br>\n飲酒後の入浴には要注意！急激な気温変化とアルコールの作用で心臓や血管に大きな負担がかかります。飲酒直後の入浴は避け、どうしても入りたい時はぬるめのシャワーで済ませるようにしましょう。\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは11月より%d点上がりました。<br>\n冬は睡眠の質が下がりやすい季節といわれます。睡眠環境を整え、質の高い睡眠を目指しましょう。睡眠に役立つ情報は<a href=\"https://go.andwell.jp/library/tag/9b020f0e-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から。\n\n",
      "down": "睡眠のスコアは11月より%d点下がりました。<br>\n冬は睡眠の質が下がりやすい季節といわれます。睡眠環境を整え、質の高い睡眠を目指しましょう。睡眠に役立つ情報は<a href=\"https://go.andwell.jp/library/tag/9b020f0e-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から。\n\n",
      "equal": "睡眠のスコアは11月と同じ点数です。<br>\n冬は睡眠の質が下がりやすい季節といわれます。睡眠環境を整え、質の高い睡眠を目指しましょう。睡眠に役立つ情報は<a href=\"https://go.andwell.jp/library/tag/9b020f0e-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは11月より%d点上がりました。<br>\n普段から❝<b>深呼吸</b>❞していますか？深呼吸には自律神経を整え、ストレスを軽減する効果があります。忙しい時こそ、深い呼吸を意識してみましょう。\n\n",
      "down": "ストレスのスコアは11月より%d点下がりました。<br>\n普段から❝<b>深呼吸</b>❞していますか？深呼吸には自律神経を整え、ストレスを軽減する効果があります。忙しい時こそ、深い呼吸を意識してみましょう。\n\n",
      "equal": "ストレスのスコアは11月と同じ点数です。<br>\n普段から❝<b>深呼吸</b>❞していますか？深呼吸には自律神経を整え、ストレスを軽減する効果があります。忙しい時こそ、深い呼吸を意識してみましょう。\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your lifestyle score has gone up %d points since November. Which items have you seen change?<br>\nA new year has arrived.\r\n<b>New Year's resolutions should start on New Year's Day</b>. Why not find at least one healthy habit goal that you can start today, so that you can spend this year full of energy and vitality? We also recommend referring to <a href=\"https://go.andwell.jp/library/tag/a21260e3-5e2f-11ec-9ce8-0af363eb5062\">our column on &well</a>.\n\n",
      "down": "Your lifestyle score has gone down %d points since November.  Which items have you seen change?<br>\nA new year has arrived.\r\n<b>New Year's resolutions should start on New Year's Day</b>. Why not find at least one healthy habit goal that you can start today, so that you can spend this year full of energy and vitality? We also recommend referring to <a href=\"https://go.andwell.jp/library/tag/a21260e3-5e2f-11ec-9ce8-0af363eb5062\">our column on &well</a>.\n\n",
      "equal100": "Your lifestyle score is perfect, like it was in November. Excellent!<br>\nA new year has arrived.\r\n<b>New Year's resolutions should start on New Year's Day</b>. Why not find at least one healthy habit goal that you can start today, so that you can spend this year full of energy and vitality? We also recommend referring to <a href=\"https://go.andwell.jp/library/tag/a21260e3-5e2f-11ec-9ce8-0af363eb5062\">our column on &well</a>.\n\n",
      "equal": "Your lifestyle score is the same as it was in November.<br>\nA new year has arrived.\r\n<b>New Year's resolutions should start on New Year's Day</b>. Why not find at least one healthy habit goal that you can start today, so that you can spend this year full of energy and vitality? We also recommend referring to <a href=\"https://go.andwell.jp/library/tag/a21260e3-5e2f-11ec-9ce8-0af363eb5062\">our column on &well</a>.\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d points since November. Keep up the good work!<br>\nWe started accepting entries for <b>the 3rd Individual Walk Challenge</b> on January 17. This is the last Individual Challenge of the fiscal year, so let's all aim to get our best record ever!\n\n",
      "down": "Your steps score has gone down %d points since November. <br>\nWe started accepting entries for <b>the 3rd Individual Walk Challenge</b> on January 17. This is the last Individual Challenge of the fiscal year, so let's all aim to get our best record ever!\n\n",
      "equal": "Your steps score is the same as it was in November. <br>\nWe started accepting entries for <b>the 3rd Individual Walk Challenge</b> on January 17. This is the last Individual Challenge of the fiscal year, so let's all aim to get our best record ever!\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d points since November. Excellent!<br>\nModerate exercise also <b>improves the immune system</b>. You may also want to refer to <a href=\"https://go.andwell.jp/library/tag/9caf9806-5e2f-11ec-9ce8-0af363eb5062\">this page</a>.\n\n",
      "down": "Your exercise score has gone down %d points since November. Let's take a look back at the last month.<br>\nModerate exercise also <b>improves the immune system</b>. You may also want to refer to <a href=\"https://go.andwell.jp/library/tag/9caf9806-5e2f-11ec-9ce8-0af363eb5062\">this page</a>.\n\n",
      "equal": "Your exercise score is the same as it was in November. <br>\nModerate exercise also <b>improves the immune system</b>. You may also want to refer to <a href=\"https://go.andwell.jp/library/tag/9caf9806-5e2f-11ec-9ce8-0af363eb5062\">this page</a>.\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d points since November.<br>\nEating too much for just one day is not enough to gain fat. What you eat after overeating is important. Eat a vegetable-based menu with plenty of <b>fiber</b> and <b>vitamins</b>. The key is to make <b>adjustments within 48 hours</b>.\n\n",
      "down": "Your food score has gone down %d points since November.<br>\nEating too much for just one day is not enough to gain fat. What you eat after overeating is important. Eat a vegetable-based menu with plenty of <b>fiber</b> and <b>vitamins</b>. The key is to make <b>adjustments within 48 hours</b>.\n\n",
      "equal": "Your food score is the same as it was in November. <br>\nEating too much for just one day is not enough to gain fat. What you eat after overeating is important. Eat a vegetable-based menu with plenty of <b>fiber</b> and <b>vitamins</b>. The key is to make <b>adjustments within 48 hours</b>.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d points since November.<br>\nBe careful about taking a bath after drinking! The sudden change in temperature and the effects of the alcohol can put a major strain on the heart and blood vessels. Avoid taking a bath immediately after drinking, and if you must do something like that, take a lukewarm shower.\n\n",
      "down": "Your alcohol score has gone down %d points since November. Be careful not to drink too much.<br>\nBe careful about taking a bath after drinking! The sudden change in temperature and the effects of the alcohol can put a major strain on the heart and blood vessels. Avoid taking a bath immediately after drinking, and if you must do something like that, take a lukewarm shower.\n\n",
      "equal": "Your alcohol score is the same as it was in November. <br>\nBe careful about taking a bath after drinking! The sudden change in temperature and the effects of the alcohol can put a major strain on the heart and blood vessels. Avoid taking a bath immediately after drinking, and if you must do something like that, take a lukewarm shower.\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d points since November.<br>\nWinter is said to be the season when the quality of sleep tends to decline. Let's improve our sleeping environment and aim for good-quality sleep. <a href=\"https://go.andwell.jp/library/tag/9b020f0e-5e2f-11ec-9ce8-0af363eb5062\">Click here</a> for useful information on sleep.\n\n",
      "down": "Your sleep score has gone down %d points since November.<br>\nWinter is said to be the season when the quality of sleep tends to decline. Let's improve our sleeping environment and aim for good-quality sleep. <a href=\"https://go.andwell.jp/library/tag/9b020f0e-5e2f-11ec-9ce8-0af363eb5062\">Click here</a> for useful information on sleep.\n\n",
      "equal": "Your sleep score is the same as it was in November. <br>\nWinter is said to be the season when the quality of sleep tends to decline. Let's improve our sleeping environment and aim for good-quality sleep. <a href=\"https://go.andwell.jp/library/tag/9b020f0e-5e2f-11ec-9ce8-0af363eb5062\">Click here</a> for useful information on sleep.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d points since November.<br>\nDo you take <b>deep breaths</b> on a regular basis? Breathing deeply has the effect of regulating the autonomic nervous system and reducing stress. Let's make a conscious effort to do it when we're busy.\n\n",
      "down": "Your stress score has gone down %d points since November.<br>\nDo you take <b>deep breaths</b> on a regular basis? Breathing deeply has the effect of regulating the autonomic nervous system and reducing stress. Let's make a conscious effort to do it when we're busy.\n\n",
      "equal": "Your stress score is the same as it was in November. <br>\nDo you take <b>deep breaths</b> on a regular basis? Breathing deeply has the effect of regulating the autonomic nervous system and reducing stress. Let's make a conscious effort to do it when we're busy.\n\n"
    }
  }
}